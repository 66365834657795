<template>
    <div class="pa-menu">
        <div class="pa-menu__list">
            <router-link
                v-if="isSbg"
                :to="{
                    name: 'PersonalAreaLoans'
                }"
                class="pa-menu__item"
            >
                <span class="pa-menu__icon">
                    <span class="icon-suggestion"></span>
                </span>
                Предложения
            </router-link>
            <!-- <router-link
                :to="{
                    name: 'PersonalAreaSubscription'
                }"
                class="pa-menu__item"
            >
                <span class="pa-menu__icon">
                    <span class="icon-success"></span>
                </span>
                Подписки
            </router-link> -->
            <router-link
                :to="{
                    name: 'PersonalAreaProfile'
                }"
                class="pa-menu__item"
            >
                <span class="pa-menu__icon">
                    <span class="icon-profile"></span>
                </span>
                Профиль
            </router-link>
            <router-link
                :to="{
                    name: 'PersonalAreaDocuments'
                }"
                class="pa-menu__item"
            >
                <span class="pa-menu__icon">
                    <span class="icon-documents"></span>
                </span>
                Документы
            </router-link>
            <router-link
                :to="{
                    name: 'PersonalAreaFeedback'
                }"
                class="pa-menu__item"
            >
                <span class="pa-menu__icon">
                    <span class="icon-comment"></span>
                </span>
                Обратная связь
            </router-link>
            <p
                class="pa-menu__item"
                @click="logout"
            >
                <span class="pa-menu__icon">
                    <span class="icon-exit"></span>
                </span>
                Выйти
            </p>
        </div>
    </div>
</template>

<script>
import sbg from '@/mixins/sbg';
import './menu.scss';

import Cookies from 'js-cookie';

export default {
    name: 'Menu',
    mixins: [
        sbg,
    ],
    methods: {
        async logout() {
            Cookies.remove('sbg-identity')
            Cookies.remove('sbg-in')
            Cookies.remove('PHPSESSID')
            window.location.reload()
        }
    }
}
</script>