var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pa-menu"},[_c('div',{staticClass:"pa-menu__list"},[(_vm.isSbg)?_c('router-link',{staticClass:"pa-menu__item",attrs:{"to":{
                name: 'PersonalAreaLoans'
            }}},[_c('span',{staticClass:"pa-menu__icon"},[_c('span',{staticClass:"icon-suggestion"})]),_vm._v(" Предложения ")]):_vm._e(),_c('router-link',{staticClass:"pa-menu__item",attrs:{"to":{
                name: 'PersonalAreaProfile'
            }}},[_c('span',{staticClass:"pa-menu__icon"},[_c('span',{staticClass:"icon-profile"})]),_vm._v(" Профиль ")]),_c('router-link',{staticClass:"pa-menu__item",attrs:{"to":{
                name: 'PersonalAreaDocuments'
            }}},[_c('span',{staticClass:"pa-menu__icon"},[_c('span',{staticClass:"icon-documents"})]),_vm._v(" Документы ")]),_c('router-link',{staticClass:"pa-menu__item",attrs:{"to":{
                name: 'PersonalAreaFeedback'
            }}},[_c('span',{staticClass:"pa-menu__icon"},[_c('span',{staticClass:"icon-comment"})]),_vm._v(" Обратная связь ")]),_c('p',{staticClass:"pa-menu__item",on:{"click":_vm.logout}},[_vm._m(0),_vm._v(" Выйти ")])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"pa-menu__icon"},[_c('span',{staticClass:"icon-exit"})])
}]

export { render, staticRenderFns }